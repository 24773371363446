/*! {!project_name!} v0.1.12 */
/**
 * JS for: {!project_name!} v0.1.12
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 * 
 */
const slick = require('slick-carousel');
const lightcase = require('lightcase');
document_width = jQuery(document).width();
let $ = require('jquery');

/* Form validations */
/**
 * Email validation
 *
 * @param  string email email input field value
 *
 * @return boolean
 */
function email_validation(email) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
    return true;
  }
  return false;
}
/**
 * Form validations
 *
 * @param  string form Form ID
 *
 * @return boolean
 */
function form_validations(form)
{
  var result = false;
  var form_id = '#' + form;

  switch(form) {
    case 'form-newsletter-subscription':
      if (email_validation($(form_id + ' .input-email').val()) && 
          '' == $(form_id + ' .input-verification').val()) {
        result = true;
      }
      break;
    case 'form-contact':

   
      if ('' != $(form_id + ' .input-name').val() && 
          email_validation($(form_id + ' .input-email').val()) && 
          '' != $(form_id + ' .input-telephone').val() &&
          //'' != $(form_id + ' .input-product').val() && 
          '' != $(form_id + ' .input-comments').val() &&
          '' == $(form_id + ' .input-verification').val()) {
        result = true;
      }
      break;
  }
  return result;
}
/**
 * Form disable
 *
 * Toggle form submit, button label and button loader animation
 *
 * @param  string form Form ID
 * @param  boolean disable
 */
function form_disable(form, disable)
{
  if (disable) {
    $('#' + form + ' .button-submit').prop('disabled', true);
    $('#' + form + ' .label').addClass('hidden');
    $('#' + form + ' .loader').removeClass('hidden');
  } else {
    $('#' + form + ' .button-submit').prop('disabled', false);
    $('#' + form + ' .label').removeClass('hidden');
    $('#' + form + ' .loader').addClass('hidden');
  }
}
/**
 * recaptcha callback
 *
 */
function recaptcha_callback()
{
  ajax_submit('form-contact');
}
window.recaptcha_callback = recaptcha_callback;
/**
 * Ajax submit
 *
 * @param  string form Form ID
 *
 */
function ajax_submit(form)
{
  var notification_close_label = '<label for="check-notification-toggle" id="label-notification-toggle" class="float-r">X</label>'
  form_disable(form, true);
  /* Js validations */

  if (form_validations(form)) {
    $('.check-notification').prop('checked', true);
    var data = $('#' + form).serializeArray();
    data.push({name: 'is_ajax', value: true});
    console.log(data);
    var URLactual = window.location;
    //URLactual.replace('#seccion-contacto', '');
    //URLactual=URLactual + '#seccion-contacto';

    /* Ajax */
    $.ajax({
      type: 'POST',
      url: URLactual,
      data: data,
      dataType: 'json',
      success: function(data)
      {
        if (data.status) {
          console.log(data);
          $('.notification')
            .removeClass('information warning success error')
            .addClass(data.notification.type)
            .html('')
            .html(data.notification.message + notification_close_label);
          $('.check-notification').prop('checked', false);
          $('#' + form).trigger('reset');
        } else {
          console.log(data);
          $('.notification')
            .removeClass('information warning success error')
            .addClass(data.notification.type)
            .html('')
            .html(data.notification.message + notification_close_label);
          $('.check-notification').prop('checked', false);
        }
        form_disable(form, false);
      },
      error: function(data)
      {
        console.log(data);
        $('.notification')
          .addClass('error')
          .html('')
          .html('Ocurrio un error al enviar la información, por favor intentalo nuevamente.' + notification_close_label);
        $('.check-notification').prop('checked', false);
        form_disable(form, false);
      }
    });

  } else {
    $('.notification')
      .addClass('warning')
      .html('')
      .html('Verifica que hayas llenado todos los campos correctamentes.' + notification_close_label);
    $('.check-notification').prop('checked', false);
    form_disable(form, false);
  }

  if ($('.notification').is(':visible')) {
    $('.notification').fadeTo('fast', 0.25).fadeTo('slow', 1.0);
  }
}

const media_query = window.matchMedia('(max-width: 1024px)')
function swap_social_media_links(e)
{
  let social_media_links = $('.menu .social-media-links').remove();
  if (e.matches) {
    social_media_links.appendTo('.menu');
  } else {
    social_media_links.prependTo('.menu');
  }
}

/**
 * jQuery document ready
 */
$(document).ready(function()
{
    /* The resize window monitor */
  var window_width = window.innerWidth || $(window).width();
  var window_height = window.innerHeight || $(window).height();
  $(window).resize(function() 
  {
    window_width = window.innerWidth || $(window).width();
    window_height = window.innerHeight || $(window).height();
  });
/* productos*/


  $('.proteinas-carnicas-link').on('click', function(e)
  { //alert('entro');
    $('.proteinas-carnicas-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.carrageninas-link').on('click', function(e)
  { //alert('entro');
    $('.carrageninas-link-contenido').slideToggle();
    e.preventDefault();
  });
$('.fosfatos-link').on('click', function(e)
  { //alert('entro');
    $('.fosfatos-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.proteinas-soya-link').on('click', function(e)
  { //alert('entro');
    $('.proteinas-soya-link-contenido').slideToggle();
    e.preventDefault();
  });
$('.colores-link').on('click', function(e)
  { //alert('entro');
    $('.colores-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.unidades-funcionales-link').on('click', function(e)
  { //alert('entro');
    $('.unidades-funcionales-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.marinadores-tumbleo-alta-y-bajo-rendimiento-link').on('click', function(e)
  { //alert('entro');
    $('.marinadores-tumbleo-alta-y-bajo-rendimiento-link-contenido').slideToggle();
    e.preventDefault();
  });
$('.conservadores-quimicos-link').on('click', function(e)
  { //alert('entro');
    $('.conservadores-quimicos-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.conservadores-quimicos2-link').on('click', function(e)
  { //alert('entro');
    $('.conservadores-quimicos2-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.almidones-modificados-de-maiz-link').on('click', function(e)
  { //alert('entro');
    $('.almidones-modificados-de-maiz-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.unidades-hechas-a-la-medida-link').on('click', function(e)
  { //alert('entro');
    $('.unidades-hechas-a-la-medida-link-contenido').slideToggle();
    e.preventDefault();
  });


$('.carnes-frias-y-embutidos-link').on('click', function(e)
  { //alert('entro');
    $('.carnes-frias-y-embutidos-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.otros-link').on('click', function(e)
  { //alert('entro');
    $('.otros-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.almidones-nativos-especializados-link').on('click', function(e)
  { //alert('entro');
    $('.almidones-nativos-especializados-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.glucosas-link').on('click', function(e)
  { //alert('entro');
    $('.glucosas-link-contenido').slideToggle();
    e.preventDefault();
  });

$('.maltodaxina-link').on('click', function(e)
  { //alert('entro');
    $('.maltodaxina-link-contenido').slideToggle();
    e.preventDefault();
  });


$('.colorante-link').on('click', function(e)
  { //alert('entro');
    $('.colorante-link-contenido').slideToggle();
    e.preventDefault();
  });



  /* Some responsive tricks */
  //submenu_links_swap();
  //$(window).on('resize', submenu_links_swap);
  //$('.check-menu-toggle').on('change', header_logo_swap);
  
  /* The lightcase */
  /* var iframe_width = Math.round(window_width * .60);
   var iframe_height = Math.round(window_height * .60);
   if (1140 > window_width) {
    iframe_width = Math.round(window_width * .90);
    iframe_height = Math.round(window_height * .60);
   }
   $('a[data-rel^=lightcase]').lightcase({
    showTitle: false,
    showCaption: false,
    forceWidth: true,
    forceHeight: true,
    maxWidth: 1140,
    iframe: {
      width: iframe_width,
      height: iframe_height
    }
  });
   $('a[data-rel^=lightcase-video]').lightcase({
    showTitle: false,
    showCaption: false,
    maxWidth: 1140
  });*/

  /* function submenu_links_swap()
{
  w_width = $(document).width();
  if (1180 >= $(document).width()) {
    if ($('header .submenu a.sub').length) { // Move if links are on init wrapper
      $item = $('header .submenu a.sub').clone();

      $('header .submenu a.sub').remove();
      $item.appendTo('header .submenu-alt');
    }
  } else { // Restore to init wrapper
    if ($('header .submenu-alt a.sub').length) {
      $item = $('header .submenu-alt a.sub').clone();
      $('header .submenu-alt a.sub').remove();
      $item.appendTo('header .submenu');
    }
  } 
}*/

	/* The slick slider */
	/* * Main */
	if ($('.slick-slider.main').length) {
		$('.slick-slider.main').slick({
      lazyLoad: 'ondemand',
      autoplay: true,
      autoplaySpeed: 5000,
			dots: true, 
			prevArrow: '<div class="slick-prev"><</div>',
  	  		nextArrow: '<div class="slick-next">></div>',
  	  		infinite: true,
  	  		adaptiveHeight: false
		});
	}
  /* * Products */
  if ($('.slick-slider.products').length) {
    $('.slick-slider.products').slick({
      lazyLoad: 'ondemand',
      autoplay: true,
      autoplaySpeed: 10000,
      dots: true, 
      prevArrow: '<div class="slick-prev"><</div>',
          nextArrow: '<div class="slick-next">></div>',
          infinite: true,
          adaptiveHeight: false
    });
  }

/* The lightcase */
   var iframe_width = Math.round(window_width * .60);
   var iframe_height = Math.round(window_height * .60);

   if (1140 > window_width) {
    iframe_width = Math.round(window_width * .90);
    iframe_height = Math.round(window_height * .60);

   }
   $('a[data-rel^=lightcase]').lightcase({
    showTitle: false,
    showCaption: false,
    forceWidth: true,
    forceHeight: true,
    maxWidth: 1140,
    iframe: {
      width: '100%',//iframe_width,
      height: '100%',//iframe_height
      scrolling:'yes'
    }
  });
   $('a[data-rel^=lightcase-video]').lightcase({
    showTitle: false,
    showCaption: false,
    maxWidth: 1140
  });
  /* $('.btn_equipo').on('click', function(e)
  { alert('entro');
    $('#cabecera-dos').css("cssText",  "z-index: -1");
    //e.preventDefault();
  });
   $('.lightcase-icon-close').on('click', function(e)
  { alert('salio');
    $('#cabecera-dos').css("cssText",  "z-index: 1");
    //e.preventDefault();
  });*/
  /* The ajax bind */
  /*
  if ($('form').length) {
    $('form').attr('novalidate', 'novalidate').on('submit', function(e)
    {
      if($('g-recaptcha', this).length) {
        grecaptcha.execute({ action: 'homepage' });
      } else {
        ajax_submit($(this).attr('id'));
      }
      e.preventDefault();
      e.stopPropagation();
    });
  }


  media_query.addListener(swap_social_media_links);
  swap_social_media_links(media_query);


});
*/
/* The ajax bind */
  if ($('form').length) {
    $('form').attr('novalidate', 'novalidate').on('submit', function(e)
    {
      //console.log('lo que sea');
      let recaptcha_action = $('#' + $(this).attr('id') + ' .g-recaptcha').data('action');
      grecaptcha.execute({ action: recaptcha_action });
      e.preventDefault();
      e.stopPropagation();
    });
  }

  if ($('a.temp-nav').length) {
    $('a.temp-nav').on('click', function(e)
    {
      $('a.temp-nav').removeClass('active');
      $('.temp-content').addClass('hidden');
      $(this).addClass('active');
      $('.temp-content.' + $(this).data('cat')).removeClass('hidden');
      e.preventDefault();
    })
  }

});
